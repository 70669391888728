<template>
  <!-- 课程学习 -->
  <div class="main-contain_con" @click="emojiDis = true">
    <!-- <div class="contain_up">
      <div class="title_con">
        <div class="title_text_border">
          <div class="left_circle"></div>
          <div class="right_circle"></div>
          <div class="title_text">{{ coursedesc ? coursedesc.name : "" }}</div>
          <div class="time_text">
            开课时间: {{ coursedesc.start_time | dataformat }} -
            {{ coursedesc.end_time | dataformat }}
          </div>
        </div>
      </div>
    </div>-->
    <div class="contain_down">
      <div class="introduction">
        <div class="liveCont">
          <div class="liveHeader">
            <!-- <div class="adminCont">
              <div class="adminImg">
                <el-avatar class="img" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
                </el-avatar>
              </div>
              <div class="adminInfo">
                <div>{{name}}</div>
                <div>{{identity}}</div>
              </div>
            </div>-->
            <div class="title">{{ title }}</div>
            <div class="timeCont">
              <span class="el-icon-time"></span>
              {{ start_time | dataformat }} - {{ end_time | dataformat }}
            </div>
          </div>
          <div id="isDanmu">
            <vue-danmaku
              ref="danmaku"
              class="danmaku"
              v-model="barrageList"
              use-slot
              :speeds="160"
              :channels="3"
              :isSuspend="true"
            >
              <template v-slot:dm="{ danmu }">
                <div class="danmu-item">
                  <!-- <img class="avatar" :src="danmu.avatar" alt=""> -->
                  <img class="msgImg" v-if="danmu.type" :src="danmu.msg" alt />
                  <span v-else>{{ danmu.msg }}</span>
                </div>
              </template>
            </vue-danmaku>
          </div>
          <div class="chat_video_Cont">
            <div class="chat_video">
              <div class="poster">
                <div :style="{ 'background-image': `url(${imgsrc})` }"></div>
              </div>
              <video
                ref="myVideo"
                id="isLiveVideo"
                class="video-js vjs-default-skin vjs-big-play-centered"
                controls
                controlslist="nodownload"
                muted
                preload="auto"
                @contextmenu.prevent="handleRMouse"
              >
                <!-- <video ref="myVideo" id="isLiveVideo" class="video-js vjs-default-skin vjs-big-play-centered" controls
                preload="auto" :poster="imgsrc" muted>-->
                <source :src="liveUrl" />
              </video>
            </div>
            <div class="chatRoomCont">
              <div style="height: 100%">
                <template>
                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="聊天" name="first" class="chatRoomTab">
                      <div class="chatRoomWrap">
                        <div v-for="(item, index) in textMsg" :key="index">
                          <div class="chatUser" v-if="item.identity === 'user'">
                            <div>
                              <el-avatar class="chatUserImg" :src="item.imgurl"></el-avatar>
                            </div>
                            <div class="cardCont">
                              <div class="arrowUser"></div>
                              <el-card shadow="never">
                                <div v-html="item.msg"></div>
                              </el-card>
                            </div>
                          </div>
                          <div class="chatSelf" v-if="item.identity === 'self'">
                            <div class="cardCont">
                              <el-card shadow="never">
                                <div v-html="item.msg"></div>
                              </el-card>
                              <div class="arrowSelf"></div>
                            </div>
                            <div>
                              <el-avatar class="chatSelfImg" :src="item.imgurl"></el-avatar>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="提问" name="second" class="questionTab">
                      <div class="questionWrap">
                        <div
                          class="question-item"
                          v-for="(v, i) in questionList"
                          :key="i"
                          :class="v.reply_number ? 'reply-cont-item' : ''"
                        >
                          <div class="avatar-cont">
                            <img :src="v.live_user_info.avatar || defaultAvatar" alt />
                          </div>
                          <div class="post-cont">
                            <div class="post">
                              <div class="user-info">{{ v.live_user_info.username }}</div>
                              <div class="question-text">{{ v.content | strformat }}</div>
                              <div class="time-cont">
                                <div>{{ v.updated_at | dataformat }}</div>
                                <div class="icons-cont">
                                  <div class="news-cont" @click="showIssue(v)">
                                    <img :src="news" alt class="new-icon" />
                                    <span>{{ v.reply_number }}</span>
                                  </div>
                                  <div class="question-cont" @click="clickSame(v)">
                                    <img
                                      :src="v.is_same_ask ? questioned : question"
                                      alt
                                      class="question-icon"
                                    />
                                    <span>
                                      {{
                                      v.same_count
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <template v-if="v.flag">
                              <div v-for="(el, index) in v.replyList" :key="index">
                                <div class="reply-item">
                                  <div class="reply-avatar-cont">
                                    <img :src="el.live_user_info.avatar || defaultAvatar" alt />
                                  </div>
                                  <div class="reply-cont">
                                    <div class="reply-user-info">{{ el.live_user_info.username }}</div>
                                    <div class="reply-question-text">{{ el.content | strformat }}</div>
                                    <div class="reply-time-cont">
                                      <div>{{ el.updated_at | dataformat }}</div>
                                      <div class="reply-icons-cont">
                                        <div class="reply-news-cont" @click="showIssue(el)">
                                          <img :src="news" alt class="reply-new-icon" />
                                          <span>{{ el.reply_number }}</span>
                                        </div>
                                        <div class="reply-question-cont" @click="clickSame(el)">
                                          <img
                                            :src="el.is_same_ask ? questioned : question"
                                            alt
                                            class="reply-question-icon"
                                          />
                                          <span>{{ el.same_count }}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <template>
                                      <div
                                        class="reply-item"
                                        v-for="(itemR, indexR) in el.reply_data"
                                        :key="indexR"
                                      >
                                        <div class="reply-avatar-cont">
                                          <img
                                            :src="itemR.live_user_info.avatar || defaultAvatar"
                                            alt
                                          />
                                        </div>
                                        <div class="reply-cont">
                                          <div class="reply-user-info">
                                            {{ itemR.live_user_info.username }}
                                            <span
                                              v-if="itemR.live_to_user_info"
                                            >
                                              <span style="color: #fff">
                                                :
                                                回复&nbsp;
                                              </span>
                                              {{ itemR.live_to_user_info.username }}
                                            </span>
                                          </div>
                                          <div
                                            class="reply-question-text"
                                          >{{ itemR.content | strformat }}</div>
                                          <div class="reply-time-cont">
                                            <div>{{ itemR.updated_at | dataformat }}</div>
                                            <div class="reply-icons-cont">
                                              <!-- <div class="reply-news-cont" @click="showIssue(itemR)"><img :src="news"
                                                  alt="" class="reply-new-icon"><span>{{itemR.reply_number}}</span>
                                              </div>-->
                                              <div
                                                class="reply-question-cont"
                                                @click="clickSame(itemR)"
                                              >
                                                <img
                                                  :src="itemR.is_same_ask ? questioned : question"
                                                  alt
                                                  class="reply-question-icon"
                                                />
                                                <span>{{ itemR.same_count }}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                          <div v-if="v.reply_number" class="load-more">
                            <div class="more-cont" v-if="!v.flag" @click="showMoreReply(v, i)">
                              <span>
                                ——
                                <span class="more-text">展开更多回复</span>
                              </span>
                              <span v-if="v.loading" class="el-icon-loading"></span>
                              <span v-else class="el-icon-arrow-down"></span>
                            </div>
                            <div class="pack-up" v-else @click="hideMoreReply(v, i)">
                              ——
                              <span class="pack-up-text">收起</span>
                              <span class="el-icon-arrow-up"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </template>
                <div class="iconCont">
                  <div class="imgCont">
                    <input v-show="false" ref="fileRef" type="file" @change="fileChange" />
                    <div class="chat-emoji-cont" v-show="!emojiDis">
                      <ChatEmoji v-on:selectEmoji="selectEmoji" />
                    </div>
                    <img :src="smile" alt @click.stop="emojiDis = !emojiDis" />
                    <img :src="img" alt @click="handleOpenMenu" class="sendImg" />
                  </div>
                  <!-- <div class="checkCont">
                    <el-checkbox v-model="checked">只看主讲人</el-checkbox>
                  </div>-->
                </div>
                <div class="inputCont">
                  <el-input
                    type="textarea"
                    :disabled="rolePer"
                    class="inputTextCont"
                    :placeholder="rolePerStr"
                    v-model="textarea"
                    @blur="blurEvent"
                    @keydown.native="listen($event)"
                  ></el-input>
                  <el-button
                    size="mini"
                    :disabled="rolePer"
                    type="success"
                    round
                    class="submitBtn"
                    @click="submitMsg('btn')"
                  >发送</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_con liveBotCont">
          <el-tabs class="eltabs" v-model="activeNameBot" @tab-click="handleClickSec">
            <el-tab-pane label="简介" name="first">
              <div class="class_brief">
                <span v-html="brief"></span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="公告" name="second">
              <div class="class_brief">
                <div v-for="(v, i) in noticeList" :key="i">
                  <div class="notice-item">
                    <div class="avatar-cont">
                      <img :src="live_user_info.avatar" alt />
                    </div>
                    <div class="info-cont">
                      <div class="info-item">
                        <span class="speaker">{{ live_user_info.username }}</span>
                        <span class="role">主讲人</span>
                        <span class="top" v-if="v.is_top">置顶</span>
                      </div>
                      <div class="desc-cont">
                        <span class="noticeDesc">{{ v.content }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <Customdialog
      ref="shareDialog"
      :showclose="true"
      title="分享"
      width="36%"
      type="table"
      class="shareDialog"
    >
      <div slot="dialogbody" class="dialogbody">
        <div style="text-align: center;">
          <img style="width: 170px; height: 170px; padding: 30px;" :src="qrCode" alt />
        </div>
        <!-- <div v-for="(el, index) in qrCodeItems" :key="index" class="qrCode-item">
          <div>{{el.title}}</div>
          <div>{{el.url}}</div>
          <div @click="copyLinkFn(el, index)" class="copy-link-item">
            <img :src="copyLink" alt="">
          </div>
        </div>-->
      </div>
    </Customdialog>
    <!--提问-->
    <el-drawer
      size="34%"
      title="提问"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      class="drawerQuestion"
    >
      <div class="drawerbody">
        <replyDetail :reply-data="replyData" :live-sn="liveSn" @delReply="isDelReply"></replyDetail>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import md5 from "js-md5"
import axios from "axios"
import { getToken, getWebAvaterToken } from "@/utils/auth"
import dayjs from "dayjs"
import videojs from "video.js"
import "videojs-contrib-hls"
// import 'videojs-flash'
import { getInfo, getLikes, getOnlinePe, sameAsk, getQuesList, getReplyList, noticeList, getLiveInfo, addReply } from "@/api/live"
import Customdialog from "@/components/customdialog.vue"
import QRCode from 'qrcode'
import ChatEmoji from '@/components/chatEmoji.vue'
import vueDanmaku from 'vue-danmaku'
import replyDetail from './components/reply-detail.vue'

const schoolid = sessionStorage.getItem("schoolid") * 1
const videoType = {
  "mp4": "video/mp4",
  "webm": "video/webm",
  "m3u8": "application/x-mpegURL",
}
const fileType = ["image/jpg", "image/png", "image/jpeg"]
export default {
  name: "courselearning",
  components: { Customdialog, ChatEmoji, vueDanmaku, replyDetail },
  props: {},
  data () {
    return {
      liveUrl: '',
      brief: '',
      imgsrc: '',
      sn: '',
      adminSrc: '',
      name: '',
      identity: '',
      title: '',
      start_time: '',
      end_time: '',
      activeName: 'first',
      textarea: '',
      img: require('@/assets/img.png'),
      smile: require('@/assets/微笑.png'),
      checked: false,
      textMsg: [],
      webSocket: null, //建立的连接
      socketOpen: false,
      wsUrl: 'wss://shijian.hzau.edu.cn/group-chat/', //"ws://192.168.2.8:9502",
      player: null,
      lockReconnect: false, //是否真正建立连接
      timeout: 60 * 1000, //60秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      descLike: 0,
      viewNum: 0,
      qrCode: '',
      qrCodeItems: [
        { title: '播放地址(HLS)', url: '' },
        { title: '播放地址(HLS)', url: '' },
      ],
      copyLink: require('@/assets/video/copy-link.png'),
      chat_g_sn: '',
      activeNameBot: 'first',
      live_sn: "",
      interval: null,
      emojiDis: true,
      blurIndex: 0,
      danmuFlag: true,
      barrageList: [],
      imgUrl: "",
      noticeList: [],
      defaultAvatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      news: require('@/assets/news.png'),
      newsed: require('@/assets/newsed.png'),
      question: require('@/assets/question.png'),
      questioned: require('@/assets/questioned.png'),
      questionList: [],
      live_user_info: {
        avatar: '',
        username: ''
      },
      drawer: false,
      direction: 'rtl',
      replyData: {},
      liveSn: '',
      is_shut_up: '',
      rolePerStr: '',
      rolePer: false,
      count: 0,
      _timer: null,
      status: null,    //直播状态
    }
  },
  watch: {
    textMsg: {
      handler (newVal) {
        this.$nextTick(() => {
          let resDis = document.querySelector('.chatRoomWrap')
          resDis.scrollTop = resDis.scrollHeight - resDis.clientHeight
          if (!newVal.length) return false
          if (this.danmuFlag) {
            this.addToList(newVal)
            this.danmuFlag = false
          } else {
            let resData = [newVal[newVal.length - 1]]
            this.addToList(resData)
          }
        })
      },
      immediate: true,
      deep: true
    },
    is_shut_up: {
      handler (newVal) {
        this.$nextTick(() => {
          if (newVal) {
            this.rolePerStr = '您已被主播禁言'
            this.rolePer = true
          }
        })
      },
      immediate: true,
      deep: true
    },
    activeName: {
      handler (newVal) {
        let disT = document.querySelector('.sendImg')
        if (newVal === "second") {
          this.rolePerStr = "请输入您要提出的问题"
          if (disT) {
            disT.style.display = 'none'
          }
        } else {
          this.rolePerStr = "我来说两句"
          if (disT) {
            disT.style.display = 'inline-block'
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
    strformat (val) {
      if (!val) return ""
      return val.replace(/<.*?>/ig, "")
    }
  },
  created () {
    this.live_login_flag = JSON.parse(localStorage.getItem('live_login_flag'))
    this.live_sn = this.$route.query.sn
    this.liveSn = this.$route.query.sn
    this.getInfo({
      sn: this.live_sn
    })
    this.$nextTick(() => {
      this.danmuCreat()
    })
    this.getLiveInfo()
  },
  mounted () {
    let link = document.getElementsByTagName('link')[0]
    link.href = "https://shijian.hzau.edu.cn/portal/logo.ico"
    if (this._isMobile()) {
      window.location = `https://shijian.hzau.edu.cn/live/#/?sn=${this.live_sn}`
    } else {
      window.location = `https://shijian.hzau.edu.cn/portal/#/home/livebroadcast/courselearning?sn=${this.live_sn}&header_flag=1`
    }
  },
  methods: {
    handleRMouse (e) {
      e.preventDefault()
    },
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    getVideo () {
      // let _self = this
      let resType = ''
      // this.liveUrl = 'https://media.w3.org/2010/05/sintel/trailer.mp4'//'https://hls01open.ys7.com/openlive/1c886b5e0d7a41ba86c7a183a9d065da.hd.m3u8'
      if (this.liveUrl) {
        resType = this.liveUrl.split('.')
      }
      let typeStr = videoType?.[resType[resType.length - 1]] ?? ''
      this.player = videojs(
        this.$refs.myVideo,
        {
          bigPlayButton: false,
          MsgTrackDisplay: false,
          posterImage: false,
          errorDisplay: false,
          controlBar: true,
          sources: [{
            type: typeStr,
            src: this.liveUrl
          }]
        },
        function () {
          this.pause()
        }
      )
      let dom = document.querySelector('.vjs-control-bar')
      dom.style.height = '0px'
      let newDomPlay = document.createElement('div')
      newDomPlay.setAttribute('id', 'vjs-play-cont')

      newDomPlay.appendChild(document.querySelector('.vjs-play-control'))
      newDomPlay.appendChild(document.querySelector('.vjs-current-time'))
      newDomPlay.appendChild(document.querySelector('.vjs-time-divider'))
      newDomPlay.appendChild(document.querySelector('.vjs-duration'))
      newDomPlay.appendChild(document.querySelector('.vjs-progress-control'))

      let newDomFull = document.createElement('div')
      newDomFull.setAttribute('id', 'vjs-full-cont')
      let newDomVolumeDiv = document.createElement('div') //弹幕、HD、音量等
      newDomVolumeDiv.setAttribute('class', 'vjs-volume-cont-item')

      let tanmuDom = document.createElement('button')
      tanmuDom.setAttribute('class', 'vjs-tanmu-control vjs-control vjs-button vjs-tanmu-open-control')
      tanmuDom.setAttribute('type', 'button')
      tanmuDom.setAttribute('title', 'tanmu')
      tanmuDom.setAttribute('aria-disabled', 'false')
      tanmuDom.innerHTML = `<span class="vjs-icon-placeholder" aria-hidden="true"></span>
                            <span class="vjs-control-text" aria-live="polite">tanmu</span>`
      tanmuDom.onclick = (el) => {
        let condition = el.currentTarget.className.indexOf('vjs-tanmu-open-control') !== -1
        if (condition) {
          tanmuDom.setAttribute('class', 'vjs-tanmu-control vjs-control vjs-button')
          this.danmuCreat('close')
        } else {
          tanmuDom.setAttribute('class', 'vjs-tanmu-control vjs-control vjs-button vjs-tanmu-open-control')
          this.danmuCreat()
        }
      }
      newDomVolumeDiv.appendChild(tanmuDom)

      // let HDDom = document.createElement('button')  //暂时隐藏
      // HDDom.setAttribute('class', 'vjs-HD-control vjs-control vjs-button')
      // HDDom.setAttribute('type', 'button')
      // HDDom.setAttribute('title', 'HD')
      // HDDom.setAttribute('aria-disabled', 'false')
      // HDDom.innerHTML = `<span class="vjs-icon-placeholder" aria-hidden="true"></span>
      //                    <span class="vjs-control-text" aria-live="polite">HD</span>`
      // newDomVolumeDiv.appendChild(HDDom)

      newDomVolumeDiv.appendChild(document.querySelector('.vjs-volume-panel'))
      newDomFull.appendChild(newDomVolumeDiv)

      let newDomFullDiv = document.createElement('div') //全屏、分享、在线人数、点赞等
      newDomFullDiv.setAttribute('class', 'vjs-full-cont-item')
      newDomFullDiv.appendChild(document.querySelector('.vjs-picture-in-picture-control'))
      let viewDom = document.createElement('button')
      viewDom.setAttribute('class', 'vjs-view-control vjs-control vjs-button')
      viewDom.setAttribute('type', 'button')
      viewDom.setAttribute('title', 'view')
      viewDom.setAttribute('aria-disabled', 'false')
      viewDom.innerHTML = `<span class="vjs-icon-placeholder" aria-hidden="true"></span>
                           <span class="view-text">${this.viewNum}人观看</span>
                           <span class="vjs-control-text" aria-live="polite">view</span>`
      newDomFullDiv.appendChild(viewDom)

      let likeDom = document.createElement('button')
      likeDom.setAttribute('class', 'vjs-like-control vjs-control vjs-button')
      likeDom.setAttribute('type', 'button')
      likeDom.setAttribute('title', 'like')
      likeDom.setAttribute('aria-disabled', 'false')
      likeDom.innerHTML = `<div class="vjs-like-anim" style="transform: translateX(14px);"></div>
                           <span class="vjs-icon-placeholder" aria-hidden="true"></span>
                           <span class="like-text">${this.descLike}</span>
                           <span class="vjs-control-text" aria-live="polite">like</span>`
      likeDom.onclick = () => {
        this.getLike()
      }
      newDomFullDiv.appendChild(likeDom)

      let shareDom = document.createElement('button')
      shareDom.setAttribute('class', 'vjs-share-control vjs-control vjs-button')
      shareDom.setAttribute('type', 'button')
      shareDom.setAttribute('title', 'share')
      shareDom.setAttribute('aria-disabled', 'false')
      shareDom.innerHTML = `<span class="vjs-icon-placeholder" aria-hidden="true"></span>
                            <span class="share-text">扫码分享</span>
                            <span class="vjs-control-text" aria-live="polite">share</span>`
      shareDom.onclick = () => {
        this.creatQrCode()
      }
      newDomFullDiv.appendChild(shareDom)

      newDomFullDiv.appendChild(document.querySelector('.vjs-fullscreen-control'))
      newDomFull.appendChild(newDomFullDiv)
      newDomPlay.style.width = '100%'
      newDomPlay.style.height = '40px'
      newDomPlay.style.display = 'flex'
      newDomPlay.style.position = 'absolute'
      newDomPlay.style.bottom = '55px'
      newDomPlay.style.backgroundColor = 'rgba(43, 51, 63, 0.7)'
      if ([resType[resType.length - 1]].indexOf('m3u8') !== -1) {
        newDomPlay.style.visibility = 'hidden'
      }
      dom.appendChild(newDomPlay)
      newDomFull.style.width = '100%'
      newDomFull.style.height = '55px'
      newDomFull.style.display = 'flex'
      newDomFull.style.position = 'absolute'
      newDomFull.style.bottom = '0px'
      newDomFull.style.backgroundColor = 'rgba(43, 51, 63, 0.7)'
      dom.appendChild(newDomFull)
      let resNodes = dom.childNodes
      resNodes.forEach(item => {
        let ele = item.getAttribute("class")
        if (ele !== null) {
          dom.removeChild(item)
        }
      })
      // debugger
      if (this.status === 1) {
        let dis = document.querySelector('.poster')
        dis.style['visibility'] = 'hidden'
        this.player.play()
      }
    },
    getInfo (parent_sn) {
      getInfo(parent_sn).then((response) => {
        if (response.code === 0) {
          this.liveUrl = response?.data?.play_url_list?.m3u8 ?? ''
          if (response?.data?.brief) {
            this.brief = response?.data?.brief.replace(/\\/g, '')
          }
          this.title = response?.data?.title ?? ''
          document.title = this.title
          this.name = 'admin'
          this.identity = "主讲人"
          //console.log(response, "responseresponseresponseresponse")
          this.start_time = response?.data.start_time ?? ''
          this.end_time = response?.data?.end_time ?? ''
          this.viewNum = response?.data?.on_line_number ?? 0
          this.descLike = response?.data?.likes_count ?? 0
          this.chat_g_sn = response?.data?.chat_group_sn ?? '' //CG4684684687350
          // if (response.data.play_url_list.m3u8 == null) {
          //   this.imgsrc = response?.data?.cover??'';
          // }
          this.imgsrc = response?.data?.cover ?? ''
          this.live_user_info = response?.data.live_user_info ?? {}
          // this.status = response?.data?.status ?? 0;
        }
        // 测试用数据
        // this.liveUrl = 'https://media.w3.org/2010/05/sintel/trailer.mp4'
        this.getVideo()
        this.initWebsoket()
      })
    },
    handleClick (tab, event) {
      //console.log(tab.index, '--------tab.index')
      switch (tab.index) {
        case "1":
          this.getQuestionList()
          break
        default:
          this.questionList = []
          break
      }
    },
    handleClickSec (tab, event) {
      //console.log(tab.index, '--------tab.index222')
      switch (tab.index) {
        case "1":
          this.getNoticeList()
          break
        default:
          this.noticeList = []
          break
      }
    },
    submitMsg (v) {
      if (this.activeName === "second") {  //发提问
        // debugger
        if (!this.live_login_flag) {
          this.$router.push({
            path: "/livelogin",
            query: {
              sn: this.live_sn,
              title: this.title
            }
          })
        } else {
          let params = {
            content: this.textarea,
            live_sn: this.live_sn,
          }
          addReply(params).then(res => {
            if (!res.code) {
              // this.$message.success('新增提问成功')
              this.getQuestionList()
            }
          })
        }
      } else {  //发聊天
        if (this.webSocket.readyState !== 1) {
          this.$set(this, 'textarea', '')
          // this.$message.error('服务器未连接成功!')
          // return false
          this.initWebsoket()
        } else {
          let msg = {
            type: 0,
            message: this.textarea,
            // message_id: this.chat_g_sn
          }
          let data = {
            imgurl: getWebAvaterToken() || this.defaultAvatar,
            identity: 'self'
          }
          if (v === 'btn') {
            msg.message_type = 1
            data.msg = this.textarea
          } else {
            msg.message_type = 2
            data.msg = `<img src="${this.imgUrl}" style="object-fit: contain; width: 120px" alt="" isImgType />`
          }
          if (this.textarea !== "") {
            this.webSocket.send(JSON.stringify(msg))
            this.textMsg = this.textMsg.concat([data])
          } else {
            if (this.imgUrl !== "") {
              msg.message = this.imgUrl
              this.webSocket.send(JSON.stringify(msg))
              this.textMsg = this.textMsg.concat([data])
            }
          }
        }
      }
      this.$set(this, 'textarea', '')
      this.$set(this, 'imgUrl', '')
    },
    webSocketOnopen () {
      //console.log('WebSocket连接成功!', this.webSocket.readyState)
      let newDate = Math.round(new Date().getTime() / 1000)
      this.webSocket.send(JSON.stringify({ type: 9, start_time: newDate - 15, end_time: newDate }))
      this.start(this.webSocket)
    },
    webSocketOnmessage (e) {
      let that = this
      if (e) {
        let received_msg = JSON.parse(e.data)
        if (received_msg.type === 4) {
          //更新观看人数
          let domView = document.querySelector('.view-text')
          this.viewNum = received_msg?.data?.online_num ?? this.viewNum
          domView.innerHTML = this.viewNum + '人观看'

          //更新点赞数
          let domLike = document.querySelector('.like-text')
          domLike.innerHTML = received_msg?.data?.live_likes_count ?? this.descLike

          this.status = received_msg?.data?.live_status
          let dis = document.querySelector('.poster')
          if (this.status === 1) {
            dis.style['visibility'] = 'hidden'
            this.player.play()
          } else if (this.status === 0) {
            dis.style['visibility'] = 'visible'
            this.player.pause()
          }
        } else {
          if (received_msg.type === 3) return false
          if (received_msg.message) {
            let data = [{
              msg: received_msg.message_type === 2 ? `<img src="${received_msg.message}" style="object-fit: contain; width: 120px" alt="" isImgType />` : received_msg.message,
              imgurl: received_msg?.from_user_info?.avatar || that.defaultAvatar,
              identity: 'user'
            }]
            that.textMsg = that.textMsg.concat(data)
          }
        }
      }
    },
    webSocketOnclose () {
      this.reconnect()
    },
    webSocketOnerror (e) {
      //console.log("连接异常！", e)
      this.reconnect()
    },
    start (ws) {
      this.reset()
      this.timeoutObj = setTimeout(() => {
        // //console.log('发送心跳,后端收到后，返回一个心跳消息')
        // onmessage 拿到返回的心跳就说明连接正常
        ws.send(JSON.stringify({ type: 5 }))
        this.start(ws)
        // this.serverTimeoutObj = setTimeout(() => {
        //   // 如果超过一定时间还没响应(响应后触发重置)，说明后端断开了
        //   ws.close();
        // }, this.timeout);
      }, this.timeout)
    },
    reset () {
      // this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
      this.timeoutObj && clearTimeout(this.timeoutObj)
    },
    reconnect () { //重新连接
      //console.log('尝试重连')
      if (this.lockReconnect) {
        return
      }
      this.lockReconnect = true
      this.timeoutnum && clearTimeout(this.timeoutnum)
      this.timeoutnum = setTimeout(() => {
        this.initWebsoket() //新连接
        this.lockReconnect = false
      }, 5000)
    },
    getLike () {
      let that = this
      let isClick = true
      let dom = document.querySelector('.like-text')
      let resDis = document.querySelector('.vjs-like-anim')
      if (isClick) {
        that.count = that.count + 1
        //console.log(that.count, '-----------11111')
        //console.log(that.descLike, '-----------222222')
        isClick = false
        dom.innerHTML = this.descLike + that.count
        if (that.count) {  //防连续点赞后多次请求接口
          clearTimeout(that._timer)
        }
        that._timer = setTimeout(async function () { //统计5s内的点赞次数只调用一次接口
          isClick = true
          try {
            let pramas = {
              sn: that.live_sn,
              likes_count: that.count
            }
            let res = await getLikes(pramas)
            if (!res.code) {
              dom.innerHTML = res.data.likes_count
              that.descLike = res.data.likes_count
            }
            that.count = 0
          } catch (error) {
            //console.log(error)
          } finally {
            that.count = 0
          }
        }, 5 * 1000)
        //井喷爱心效果
        for (let i = 0; i < 8; i++) {
          setTimeout(() => {
            const fullHeart = document.createElement('div')
            fullHeart.classList.add('hearts')
            fullHeart.innerHTML = '<span class="heart"></span>'
            fullHeart.style.transform = `scale(${Math.random() * 1})`
            resDis.appendChild(fullHeart)
            setTimeout(() => {
              fullHeart.remove()
            }, 2000)
          }, i * 200)
        }
      }
    },
    async creatQrCode () {
      let url = "https://shijian.hzau.edu.cn/live/#/?sn=" + this.live_sn
      this.qrCode = await QRCode.toDataURL(url)
      //console.log(url, '1---233////')
      //console.log(this.qrCode, '1---233')
      this.qrCodeItems.map(item => {
        item.url = url
      })
      this.$refs.shareDialog.dialogopenbt()
    },
    copyLinkFn (row, index) {
      let copyInput = document.createElement("input")
      copyInput.value = row.url
      document.body.appendChild(copyInput)
      copyInput.select()
      document.execCommand("copy")
      this.$message({
        type: "success",
        message: "复制成功"
      })
      document.body.removeChild(copyInput)
    },
    initWebsoket () {
      const token = getToken() || ''

      const date = new Date()
      const c_time = Math.trunc(date.getTime() / 1000)
      const sign_time = c_time.toString()
      const sign = md5(md5(sign_time) + token)
      try {
        if ('WebSocket' in window) {
          let wsUrlRes = `${this.wsUrl}?group_sn=${this.chat_g_sn}&token=${token}&type=0&sign=${sign}&time=${c_time}`
          //console.log('1---------1', wsUrlRes)
          this.webSocket = new WebSocket(wsUrlRes)

          this.webSocket.onopen = this.webSocketOnopen
          this.webSocket.onmessage = this.webSocketOnmessage
          this.webSocket.onerror = this.webSocketOnerror
          // this.webSocket.onclose = this.webSocketOnclose
        } else {
          //console.log('您的浏览器不支持websocket')
        }
      } catch (e) {
        this.reconnect()
      }
    },
    timer () {
      let dom = document.querySelector('.view-text')
      getOnlinePe({ sn: this.live_sn }).then(res => {
        if (!res.code) {
          dom.innerHTML = res.data.on_line_number + '人观看'
        }
      })
    },
    selectEmoji (e) { //将选中的表情插入到文本光标所在位置
      let index = this.blurIndex
      let resStr = this.textarea
      this.textarea = resStr.slice(0, index) + e + resStr.slice(index)
      this.blurIndex = this.blurIndex + 2
    },
    // 获取光标所在位置的index
    blurEvent (e) {
      this.blurIndex = e.srcElement.selectionStart
    },
    danmuCreat (e) {
      let resDis = this.$refs['danmaku']
      if (e === "close") {
        resDis.stop()
        resDis.hide()
      } else {
        resDis.play()
        resDis.show()
      }
    },
    addToList (list) {
      list.forEach((v) => {
        let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
        //console.log(v.msg.match(srcReg), '2----3')
        this.barrageList.push({
          avatar: v.imgurl,
          msg: v.msg.includes('isImgType') ? v.msg.match(srcReg)[1] : v.msg,
          type: v.msg.includes('isImgType') ? 1 : 0,
        })
      })
    },
    handleOpenMenu () {
      this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
    },
    fileChange (e) {
      let condition = fileType.includes(e.currentTarget.files[0].type)
      if (condition) {
        let formData = new FormData()
        formData.append('file', e.currentTarget.files[0])

        const date = new Date()
        const c_time = Math.trunc(date.getTime() / 1000)
        const sign_time = c_time.toString()
        const app_version = "v1.0"
        axios({
          url: process.env.VUE_APP_BASE_API + "/common/upload-file/upload",
          method: 'post',
          data: formData,
          headers: {
            Authorization: "Bearer " + getToken(),
            fromtype: 2,
            identifier: 2,
            schoolid: schoolid ? schoolid : 1,
            "app-version": app_version,
            "request-time": c_time,
            "request-sign": md5(md5(sign_time) + app_version),
          }
        }).then(res => {
          if (res.data.code === 0) {
            this.imgUrl = res.data.data[0].url
            this.submitMsg()
          } else if (res.data.code == 403) {
            this.$router.push({
              path: "/livelogin",
              query: {
                sn: this.live_sn,
                title: this.title
              }
            })
          }
        }).catch(error => {
          //console.log(error)
        })
      } else {
        return false
      }
    },
    listen (e) {
      if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault() // 阻止浏览器默认换行和空格操作
        return false
      }
    },
    getNoticeList () {
      noticeList({ live_sn: this.live_sn }).then(res => {
        if (res.code === 0) {
          this.noticeList = res.data.data
        }
      })
    },
    getQuestionList () {
      getQuesList({ live_sn: this.live_sn }).then(res => {
        if (res.code === 0) {
          res.data.data.forEach(item => {
            item.replyList = []
            item.flag = false
          })
          this.questionList = res.data.data
        }
      })
    },
    async showMoreReply (row, index) {
      try {
        this.$set(row, 'loading', true)
        let res = await getReplyList({ comment_id: row.id })
        if (res.code === 0) {
          this.$set(row, 'loading', false)
          this.$set(row, 'replyList', res.data.data)
          this.$set(row, 'flag', true)
          //console.log(row.replyList, '--------------1')
        }
      } catch (error) {
        //console.log(error)
      } finally {
        this.$set(row, 'loading', false)
      }
    },
    hideMoreReply (row, index) {
      this.$set(row, 'replyList', [])
      this.$set(row, 'flag', false)
    },
    handleClose () {
      this.getQuestionList()
      this.drawer = false
    },
    showIssue (data) {
      if (!this.live_login_flag) {
        this.$router.push({
          path: "/livelogin",
          query: {
            sn: this.live_sn,
            title: this.title
          }
        })
      } else {
        this.replyData = data
        this.drawer = true
      }
    },
    clickSame (data) {
      if (!this.live_login_flag) {
        this.$router.push({
          path: "/livelogin",
          query: {
            sn: this.live_sn,
            title: this.title
          }
        })
      } else {
        sameAsk({ id: data.id }).then(res => {
          if (res.code === 0) {
            this.getQuestionList()
          }
        })
      }
    },
    isDelReply (data) {
      if (data) {
        this.getQuestionList()
        this.drawer = false
      }
    },
    getLiveInfo () {
      getLiveInfo({ live_sn: this.live_sn }).then(res => {
        if (res.code === 0) {
          this.is_shut_up = res.data?.is_shut_up
          if (res.data?.is_delete) {
            this.$message.error('您已被主播移出直播间!')
            this.webSocket.close()
          }
        }
      })
    }
  },
  beforeDestroy () {
    this.reset()
    if (this.webSocket) {
      this.webSocket.close()
    }
    if (this.player === null) return false
    this.player.dispose()
  }
};
</script>
<style scoped lang="scss">
.header_hid {
  visibility: hidden;
}

.main-contain_con {
  margin-bottom: 140px;

  .contain_up {
    // background: url(~@/assets/newchange/bg_4444.jpg) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 298px;

    .title_con {
      background: rgba(0, 0, 0, 0.5);

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      .title_text_border {
        position: relative;
        min-width: 400px;
        height: 160px;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .left_circle {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 16px;
          height: 16px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          transform: translate(-100%, -50%);
        }

        .right_circle {
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 16px;
          height: 16px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          transform: translate(100%, 50%);
        }

        .bt {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 50%);
          width: 151px;
          height: 40px;
          background: #3d84ff;
          border-radius: 20px;
          border-color: #3d84ff;
          opacity: none;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
        }

        .title_text {
          margin-top: 20px;
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          color: #ffffff;
        }

        .time_text {
          margin-top: 20px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }

  .class_brief {
    width: 100%;
    margin-top: 10px;
  }

  .vjs-big-play-centered {
    width: 45%;
    margin: 0 auto;
    min-height: 500px;
  }

  .contain_down {
    .introduction {
      margin-top: 25px;
      border-radius: 4px;

      .elrow {
        // height: 384px;
        width: 1200px;

        .elcol-left {
          // height: 384px;
          position: relative;

          .elimage {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .statusimg {
            width: 80px;
            height: 80px;
            position: absolute;
            left: -2px;
            top: -2px;
          }
        }

        .elcol-right {
          height: 100%;
          padding-left: 20px;

          .intro-title {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;

            .item {
              margin-top: 10px;
              margin-right: 100px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .elimage {
                width: 20px;
                height: 20px;
              }

              .elimage2 {
                width: 23px;
                height: 23px;
              }

              .item-name {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                white-space: nowrap;
              }

              .item-text {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                white-space: nowrap;
              }
            }
          }

          .course-item {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: baseline;

            .left-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              white-space: nowrap;

              .coursename {
                margin-left: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
              }
            }

            .right-text {
              position: relative;
              flex: 1;

              .text {
                font-size: 14px;
                line-height: 25px;
                font-weight: 400;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }

              .morebt {
                position: absolute;
                font-size: 16px;

                font-weight: 400;
                color: #3d84ff;
                bottom: 0px;
                right: 0px;
                user-select: none;
                cursor: pointer;
              }
            }

            .right-text2 {
              margin-left: -80px;
            }
          }

          .bottom-title {
            margin-left: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .item {
              margin-top: 10px;
              margin-right: 100px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .score {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }

              .num {
                font-size: 30px;
                font-weight: 500;
                color: #3d84ff;
              }

              .numname {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }
      }
    }

    .bottom {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 105px;

      .bottom_con {
        width: 100%;
        min-height: 400px;

        .tabbt-contain {
          border-bottom: 1px solid #cccccc;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .bt {
            margin-right: 90px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 6px;
            border-bottom: 2px solid transparent;
            user-select: none;
            cursor: pointer;

            .bt-icon {
              margin-right: 6px;
            }

            .bt-name {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }

            .bt-name-active {
              color: #3d84ff;
            }
          }

          .btactive {
            border-bottom: 2px solid #3d84ff;
          }
        }
      }
    }
  }

  .liveCont {
    font-family: PingFang SC;
    background-color: #0f0f1e;
    position: relative;
  }

  .liveHeader {
    height: 80px;
    background-color: #1c1d30;
    color: #ffffff;
    position: relative;
    padding: 0 105px;
    display: flex;
  }

  .adminCont {
    display: flex;
  }

  .adminImg {
    width: 54px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #ffffff;
    }
  }

  .adminInfo {
    min-width: 80px;
    margin-left: 10px;
    font-weight: bold;

    div:first-child {
      line-height: 53px;
      font-size: 18px;
    }

    div:last-child {
      line-height: 0px;
      font-size: 14px;
    }
  }

  .title {
    line-height: 80px;
    font-size: 24px;
    font-weight: bold;
    min-width: 300px;
  }

  .timeCont {
    position: absolute;
    right: 105px;
    line-height: 80px;
    font-size: 16px;
    font-weight: bold;

    span {
      margin-right: 10px;
      font-size: 18px;
    }
  }

  #isDanmu {
    height: 140px;
    width: calc(100% - 570px);
    margin-left: 105px;
    position: absolute;
    top: 100px;
    z-index: 10;
    padding-top: 10px;

    .danmaku {
      width: 100%;
      height: 100%;
    }
  }

  .chat_video_Cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 105px 0px 105px;

    #isLiveVideo {
      // width: 1350px;
      height: 782px;
      object-fit: contain;
    }
  }

  .chat_video {
    position: relative;
    width: calc(100% - 360px);

    .poster {
      background-color: rgba($color: #000000, $alpha: 0.8);
      padding: 20px 20px 75px;
      position: absolute;
      width: calc(100% - 40px);
      height: 687px;
      z-index: 2;
      visibility: visible;

      div {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .video-js {
    width: 100%;
    margin: 0px;
    object-fit: contain;
  }

  .chatRoomCont {
    width: 360px;
    height: 782px;
    background-color: #1c1d30;

    .chatRoomTab {
      height: 100%;
    }

    .chatRoomWrap {
      overflow-y: scroll;
      color: #ffffff;
      padding: 10px 20px 10px 20px;
      height: calc(100% - 20px);
      width: calc(100% - 40px);
      font-size: 12px;
      font-weight: 400;
    }

    .chatUser {
      display: flex;
      margin-bottom: 33px;

      div:first-child {
        margin-right: 10px;
      }

      .cardCont {
        width: calc(100% - 100px);
        position: relative;
      }

      .arrowUser {
        transform: translate(-8px, 10px);
        position: absolute;
        width: 0px;
        height: 0px;
        border-top: 8px solid transparent;
        border-right: 8px solid rgba($color: #ccc, $alpha: 0.2);
        border-bottom: 8px solid transparent;
        border-left: 8px solid transparent;
      }

      .el-card {
        margin-left: 8px;
      }
    }

    .chatSelf {
      display: flex;
      margin-bottom: 33px;

      .cardCont {
        width: 100%;
        margin: 0px 17px 0px 54px;
        position: relative;
      }

      .arrowSelf {
        right: 0px;
        top: 0px;
        transform: translate(16px, 10px);
        position: absolute;
        width: 0px;
        height: 0px;
        border-top: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid rgba($color: #2bc17b, $alpha: 0.3);
        z-index: 100;
      }
    }

    .chatUserImg,
    .chatSelfImg {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .iconCont {
    height: 40px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    .imgCont {
      height: 40px;
      display: inline-block;
      position: relative;
    }

    .chat-emoji-cont {
      position: absolute;
      bottom: 50px;
    }

    img {
      width: 22px;
      height: 22px;
      vertical-align: middle;
      margin: 9px;
      cursor: pointer;
    }

    img:first-child {
      margin-right: 6px;
    }

    .checkCont {
      height: 40px;
      line-height: 40px;
    }
  }

  .inputCont {
    background-color: rgba($color: #000000, $alpha: 0.2);
    padding: 15px;
    height: calc(182px - 30px);
    position: relative;

    .inputTextCont {
      height: 110px;
      padding-bottom: 5px;
    }
  }

  .submitBtn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 70px;
    height: 30px;
    background-color: #2bc17b;
    border: #2bc17b;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .qrCode-item {
    display: flex;
    justify-content: space-around;
    align-content: center;
    margin: 0px 6%;
    height: 52px;
    line-height: 52px;
    background: #f9f9f9;
  }

  .qrCode-item:last-child {
    margin-top: 20px;
  }

  .copy-link-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .notice-item {
    display: flex;
    align-items: flex-start;
    font-family: PingFang SC;
    color: #333;
    margin-bottom: 28px;

    .avatar-cont img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 20px;
    }

    .info-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        display: inline-block;
      }

      .speaker {
        font-size: 18px;
        font-weight: bold;
        padding-right: 5px;
        color: #000000;
      }

      .role {
        margin-left: 10px;
        padding: 4px 6px;
        border: 1px solid #ffa01a;
        border-radius: 3px;
        color: #ffa01a;
        font-size: 12px;
        font-weight: 600;
        box-sizing: border-box;
      }

      .top {
        margin-left: 10px;
        padding: 4px 6px;
        border: 1px solid #ff7068;
        border-radius: 3px;
        color: #ff7068;
        font-size: 12px;
        font-weight: 600;
        box-sizing: border-box;
      }
    }

    .desc-cont {
      .noticeDesc {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .questionTab {
    height: 100%;

    .questionWrap {
      font-family: PingFang SC;
      padding: 20px;
      overflow-y: scroll;
      color: #ffffff;
      height: calc(100% - 40px);
      width: calc(100% - 40px);
      font-size: 12px;
      font-weight: 400;
    }

    .questionWrap .question-item:not(:first-child) {
      margin-top: 16px;
    }

    .questionWrap .reply-cont-item + .question-item {
      margin-top: 38px;
    }

    .question-item {
      display: flex;
      align-items: flex-start;
      position: relative;

      .avatar-cont {
        width: 42px;

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-right: 20px;
        }
      }

      .user-info,
      .reply-user-info {
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        word-break: break-all;
      }

      .question-text,
      .reply-question-text {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        margin: 10px 0px;
        /*三行超出省略*/
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        word-break: break-all;
      }

      .time-cont,
      .reply-time-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #999999;

        .icons-cont,
        .reply-icons-cont {
          display: flex;
          justify-content: center;
          align-items: center;

          div {
            display: flex;
            align-items: center;

            span {
              display: inline-block;
            }
          }

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            transform: translateY(1px);
          }

          .news-cont,
          .reply-news-cont {
            margin-right: 20px;
            cursor: pointer;
          }

          .question-cont,
          .reply-question-cont {
            cursor: pointer;
          }
        }
      }

      .reply-item {
        display: flex;
        align-items: flex-start;
        margin-top: 16px;

        .reply-avatar-cont {
          margin-right: 6px;

          img {
            width: 18px;
            height: 18px;
            border-radius: 50%;
          }
        }

        .reply-cont {
          width: 100%;
        }
      }

      .reply-item:last-child .reply-cont {
        margin-bottom: 8px;
      }

      .new-icon:hover,
      .reply-new-icon:hover {
        content: url('../../../../assets/newsed.png');
      }

      .post-cont {
        // max-height: 310px;
        // overflow: hidden;
        width: calc(100% - 42px);
      }

      .load-more {
        position: absolute;
        bottom: -24px;
        left: 42px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: flex-start;

        div {
          cursor: pointer;
        }

        span {
          display: inline-block;
        }

        .more-cont {
          margin-right: 30px;

          .more-text {
            padding: 0px 8px;
          }
        }

        .pack-up-text {
          padding: 0px 8px;
        }
      }
    }
  }
}
</style>
